import React from 'react';
import Navigation from './Navigation';
import ProjectsSection from './ProjectsSection';

type MainViewProps = {
  scrolledDown: boolean;
};

const MainView = (props: MainViewProps) => {
  return (
    <div className={'main-view' + ((!props.scrolledDown && ' hidden') || '')}>
      <div className="sider sidebar">
        <div className="holder">
          <Navigation />
        </div>
      </div>
      <div className="main">
        <section id="intro">
          <div className="txt">
            <h1>
              Hello, I'm Nahuel.
              <br /> A{' '}
              <span
                className="movingLink"
                data-replace="Spain"
                // href="/"
                // rel="noreferrer"
              >
                <span>Madrid</span>
              </span>{' '}
              based Software Engineer.
            </h1>
            <br />
            <a href="#about">About me</a>
          </div>
        </section>
        <section id="about" aria-labelledby="intro-heading">
          <h2 id="intro-heading">About me</h2>
          <span>
            I am a full-stack developer with a background on principal
            investment. Investing brought me to the art of data wrangling and
            information analysis that spearhead the decision-making process,
            sparking the interest for software engineering.
          </span>
          <br />
          <span>
            I decided to shift gears and pursue a career on development that
            would allow me to build solutions full time while pushing the
            boundaries of my own skills.
          </span>
        </section>
        <ProjectsSection />
        <section id="experience" />
        <section id="curriculum" aria-labelledby="curriculum-heading">
          <h2 id="curriculum-heading">Experience</h2>
          <div className="cv">
            {/* <h3>CV</h3> */}
            <p>
              Currently working as React team lead at{' '}
              <a href="https://www.sice.com/en">SICE</a>
            </p>
          </div>
          {/* <div className="education">
            <h3>Education</h3>
            <div className="cont">
              <span>{'date'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
            </div>
            <div className="cont">
              <span>{'date'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
            </div>
            <div className="cont">
              <span>{'date'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
              <span>{'etc'}</span>
              <span>'etc'</span>
            </div>
          </div> */}
          <br />
        </section>
        {/* contact section */}
        <section id="contact" aria-labelledby="contact-heading">
          <h2 id="contact-heading">Contact</h2>
          <p>
            You can reach out to me at{' '}
            <a href="mailto:nahuel@ghastin.com">nahuel@ghastin.com</a> or
            connect through one of the socials
          </p>
          <br />
          <div></div>
          {/* Linkedin */}
          <div>
            <a href="https://www.linkedin.com/in/nahuel-ghastin/">Linkedin</a>
          </div>
          {/* Github */}
          <div>
            <a href="https://github.com/ItsLhun/">Github</a>
          </div>
          {/* Stackoverflow */}
          <div>
            <a href="https://stackoverflow.com/users/13941049/itslhun">
              StackOverflow
            </a>
          </div>
          {/* Codewars */}
          <div>
            <a href="https://www.codewars.com/users/ItsLhun">Codewars</a>
          </div>
          {/* Steam */}
          <div>
            <a href="https://steamcommunity.com/id/ItsLhun/">Steam</a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MainView;
