import { useEffect, useState } from 'react';
import './styles/index.css';
import MainView from './components/MainView';
import Hero from './components/Hero';
import Gradient from './components/Gradient';

const App = (): JSX.Element => {
  const [scrolledDown, setScrolledDown] = useState(false);

  // Handle scroll event
  const handleScroll = (): void => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      setScrolledDown(true);
    } else {
      setScrolledDown(false);
    }
  };

  useEffect(() => {
    const onScroll = () => handleScroll();
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className="App">
      {/* <Navbar scrolledDown={scrolledDown} /> */}
      <Gradient />
      <Hero />
      <MainView scrolledDown={scrolledDown} />
    </div>
  );
};

export default App;
