import React, { useEffect } from 'react';
import Contact from './Contact';

const Navigation = () => {
  // function to highlight the active link according to section
  const highlightActiveLink = () => {
    const links = document.querySelectorAll('.nav-holder a');
    const fromTop = document.documentElement.scrollTop;
    const navbarHeight = 200;

    type ElementWithHashProp = Element & { hash?: string };

    links.forEach((link: ElementWithHashProp) => {
      // if link does not have the property hash or it is empty, skip it
      if (!link.hash || link.hash === '') return;

      // Type assert to ensure the offset properties exist
      const section = document.querySelector(link.hash) as HTMLElement;
      if (section == null) return;
      if (
        section.offsetTop <= fromTop + window.innerHeight &&
        section.offsetTop + section.offsetHeight > fromTop + navbarHeight
      ) {
        link.classList.add('current');
        const allCurrents = document.querySelectorAll('.current');
        const allFirsts = document.querySelector('.first');
        if (allFirsts != null) {
          allFirsts.classList.remove('first');
        }
        allCurrents[0].classList.add('first');
      } else {
        link.classList.remove('current');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      highlightActiveLink();
    });
  }, []);

  return (
    <div
      className="nav-holder"
      role="navigation"
      aria-labelledby="main-nav-heading"
    >
      <a className="listItem" href="#intro">
        Intro
      </a>
      <a className="listItem" href="#about">
        About me
      </a>
      <a className="listItem" href="#projects">
        Projects
      </a>
      <a className="listItem" href="#curriculum">
        Experience
      </a>
      <a className="listItem" href="#contact">
        Contact
      </a>
      <Contact />
    </div>
  );
};

export default Navigation;
