import React from 'react';

const SQUARE = window.innerHeight / 33;

class Particle {
  x: number;
  y: number;
  z: number;
  vx: number;
  vy: number;
  fillColor: string;
  diameter: number;
  canvas: HTMLCanvasElement;

  constructor(canvas: HTMLCanvasElement, maxDistance = 100) {
    this.canvas = canvas;
    this.x = Math.floor(
      Math.random() * (canvas.width - 50 + maxDistance) - maxDistance / 2
    );
    this.y = Math.floor(
      Math.random() * (canvas.height / 2 + maxDistance) -
        maxDistance / 2 +
        canvas.height / 2
    );

    this.z = (Math.random() * SQUARE) / 195 + SQUARE / 100;
    this.vx = ((Math.random() * SQUARE) / 44 - SQUARE / 150) * this.z;
    this.vy = (Math.random() * SQUARE * 0.06 + SQUARE * 0.02) * this.z;
    this.fillColor = `rgba(${100 * Math.random()},${100 * Math.random()},${
      0 * Math.random() + 100
    },${0.1 * Math.random() + 0.5})`;
    this.diameter = (Math.random() * SQUARE * 0.38 + SQUARE * 0.4) * this.z;
  }
}

class ParticlesBackground {
  canvas: HTMLCanvasElement;
  fireParticles: Particle[];
  started: boolean;
  maxDistance: number;
  ctx: CanvasRenderingContext2D;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.fireParticles = [];
    this.started = false;
    this.maxDistance = SQUARE * 0.5;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
  }

  start() {
    this.generatePoints(this.canvas.width / 90);
    this.started = true;
  }
  paint() {
    if (!this.started) {
      this.start();
    }
    const ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.save();
    // ctx.fillStyle = `hsl(0, 10%, 2%)`;
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.restore();

    ctx.save();

    for (const element of this.fireParticles) {
      this.drawAllPoints(element);
      this.updatePoint(element);
    }
    ctx.restore();
  }

  generatePoints(quantity: number) {
    for (let i = 0; i < quantity; i++) {
      let fireParticle = new Particle(this.canvas, this.maxDistance);
      this.fireParticles.push(fireParticle);
    }
  }
  drawAllPoints(particle: Particle) {
    this.drawPoint(particle);
    this.drawInvertedPoint(particle);
  }
  drawPoint(fireFlake: Particle) {
    let ctx = this.ctx;
    ctx.beginPath();
    ctx.strokeStyle = 'transparent';
    ctx.fillStyle = fireFlake.fillColor;
    ctx.arc(fireFlake.x, fireFlake.y, fireFlake.diameter, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
  }
  drawInvertedPoint(fireFlake: Particle) {
    let ctx = this.ctx;
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = 'transparent';
    ctx.fillStyle = fireFlake.fillColor;
    ctx.arc(
      fireFlake.x,
      this.canvas.height - fireFlake.y,
      fireFlake.diameter,
      0,
      2 * Math.PI
    );
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
    ctx.restore();
  }
  updatePoint(fireFlake: Particle) {
    fireFlake.y += fireFlake.vy;
    if (fireFlake.x > this.canvas.width + this.maxDistance) {
      fireFlake.x = -this.maxDistance;
    } else if (fireFlake.x < -this.maxDistance) {
      fireFlake.x = this.canvas.width + this.maxDistance;
    }
    if (fireFlake.y > this.canvas.height + this.maxDistance) {
      fireFlake.y = this.canvas.height / 2;
    } else if (fireFlake.y < -this.maxDistance) {
      fireFlake.y = this.canvas.height / 2 + this.maxDistance;
    }
  }
}

const Gradient = () => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const setupParticles = () => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.save();
    ctx.fillStyle = `hsl(359, 94%, 5%)`;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const particles = new ParticlesBackground(canvas);
    const paint = () => {
      particles.paint();
      requestAnimationFrame(paint);
    };
    paint();
  };

  // use ParticlesBackground
  React.useEffect(() => {
    setupParticles();

    window.addEventListener(
      'blur',
      function () {
        console.log('blur');
      },
      false
    );
    window.addEventListener('resize', setupParticles);
    return () => {
      window.removeEventListener('resize', setupParticles);
    };
  }, []);

  // on windows rezise, ensure canvas is resized to fit
  // React.useEffect(() => {
  //   const handleResize = () => {
  //     if (canvasRef.current) {
  //       const canvas = canvasRef.current;
  //       canvas.width = window.innerWidth;
  //       canvas.height = windowHeight;
  //     }
  //   };
  //   window.removeEventListener('resize', handleResize);

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <div className="splash">
      <div className="gradient-overlay"></div>
      <canvas
        ref={canvasRef}
        className="gradient-canvas"
        width={window.innerWidth}
        height={window.outerHeight}
      />
    </div>
  );
};

export default Gradient;
