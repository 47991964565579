import React from 'react';

const Hero = () => {
  return (
    <div className="hero-main">
      <div className="content"></div>
    </div>
  );
};

export default Hero;
