import React from 'react';
import ProjectCard from './ProjectCard';

import mirrorsLieImg from '../assets/imgs/mirrorslie.png';
import mirrorsLieGif from '../assets/imgs/mirrorslieopt.gif';

import tipperImg from '../assets/imgs/tipper.png';
import tipperGif from '../assets/imgs/tipperopt.gif';

import pethutImg from '../assets/imgs/pethut.png';
import pethutGif from '../assets/imgs/pethutopt.gif';

import notificaImg from '../assets/imgs/notifica.png';
import notificaGif from '../assets/imgs/notificaopt.gif';

import portfolioImg from '../assets/imgs/portfolio.png';
import portfolioGif from '../assets/imgs/portfoliomin.gif';

type SectionProps = {};

const ProjectsSection = (_props: SectionProps) => {
  return (
    <section id={'projects'} aria-labelledby={'projects-heading'}>
      <h2 id="projects-heading">Projects</h2>
      <div className="cards">
        <div className="card-col">
          <ProjectCard
            title="Mirrors Lie"
            imgs={[mirrorsLieImg, mirrorsLieGif]}
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quae."
            repoUrl={'https://github.com/ItsLhun/mirrors-lie'}
            liveUrl={'https://mirrorslie.com/'}
            technologies={['VanillaJS', 'Canvas API']}
          />
          <ProjectCard
            title="Tipper"
            imgs={[tipperImg, tipperGif]}
            repoUrl={'https://github.com/ItsLhun/project-tipper'}
            liveUrl={'https://project-tipper.netlify.app/'}
            technologies={[
              'Express.js',
              'MongoDB',
              'React',
              'G-Maps',
              'Stripe'
            ]}
          />
        </div>
        <div className="card-col">
          <ProjectCard
            title="Pet Hut"
            imgs={[pethutImg, pethutGif]}
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quae."
            repoUrl={'https://github.com/ItsLhun/project-pet'}
            liveUrl={'https://pethut.net/'}
            technologies={[
              'Express.js',
              'MongoDB',
              'HBS',
              'Cloudinary',
              'NotyfJS'
            ]}
          />
          <ProjectCard
            title="Notifica"
            imgs={[notificaImg, notificaGif]}
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quae."
            repoUrl={'https://github.com/ItsLhun/event-tracker'}
            liveUrl={'https://notifica-engineering.netlify.app/'}
            technologies={['React', 'Styled Components', 'ChanceJS']}
          />
          <div className="card-col">
            <ProjectCard
              title="Inception"
              imgs={[portfolioImg, portfolioGif]}
              liveUrl={'Duh'}
            />
          </div>
        </div>
      </div>
      <p className="projects-disclaimer">
        Note: The projects above are demo applications meant to be a showcase of
        skills. Some of them are not fully functional.
      </p>
    </section>
  );
};

export default ProjectsSection;
