import React, { useState } from 'react';
import VanillaTilt, { TiltOptions } from 'vanilla-tilt';

const Tilt = ({
  children,
  options
}: {
  children: React.ReactNode;
  options: TiltOptions;
}) => {
  const [tilt, setTilt] = useState<any>(null);
  const tiltRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (tiltRef.current) {
      setTilt(VanillaTilt.init(tiltRef.current, options));
    }
  }, [tiltRef, options]);

  React.useEffect(() => {
    if (tilt) {
      tilt.update();
    }
  });

  return (
    <div ref={tiltRef} className="tilt-root">
      <div className="tilt-child">{children}</div>
    </div>
  );
};

type ProjectCardProps = {
  title: string;
  description?: string;
  imgs?: [string, string];
  repoUrl?: string;
  liveUrl?: string;
  technologies?: string[];
};

const ProjectCard = (props: ProjectCardProps) => {
  // Check if overlay is hovered
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tilt options={{ scale: 1, max: 10, perspective: 900 }}>
      <div className="project-card">
        <div
          className="overlay"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            background: `linear-gradient(0deg, #212225e3 10%, rgba(31, 31, 31, 0) 66% ), url(${
              isHovered && props.imgs
                ? props.imgs[1]
                : props.imgs && props.imgs[0]
            }) top center/cover`
          }}
        ></div>
        <a href={props.liveUrl} className="project-title">
          <h5>{props.title}</h5>
        </a>
        <div className="info">
          {/* Link to the actual project and title */}
          <span>
            <div className="tags">
              {props.technologies &&
                props.technologies.map((technology, index) => (
                  <span key={index}>{technology}</span>
                ))}
            </div>
          </span>
          {/* <p className="project-description">{props.description}</p> */}
          <div className="tags links">
            <a
              href={props.liveUrl}
              className={`link ${!props.liveUrl && 'disabled-link'}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.title === 'Inception' ? 'Live...demo?' : 'Live demo'}
            </a>
            <a
              href={props.repoUrl}
              className={`link ${!props.repoUrl && 'disabled-link'}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </div>
        </div>
      </div>
    </Tilt>
  );
};

export default ProjectCard;
