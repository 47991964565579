import React from 'react';

const Contact = () => {
  return (
    <div className="contact">
      <a href="mailto:nahuel@ghastin.com">nahuel@ghastin.com</a>
      <a href="https://github.com/ItsLhun/">github.com/ItsLhun</a>
      {/* <a href="tel:+34-666-666-666">666 666 666</a> */}
      <br />
    </div>
  );
};

export default Contact;
